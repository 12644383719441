import { render, staticRenderFns } from "./Degree.vue?vue&type=template&id=365ff30f&scoped=true"
import script from "./Degree.vue?vue&type=script&lang=js"
export * from "./Degree.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "365ff30f",
  null
  
)

export default component.exports